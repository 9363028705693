import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

function moveParrallax({ clientX, clientY }, element, strength = 0.016) {
  element.style.transform = `translate(${clientX * strength}px, ${clientY *
    strength}px)`
}

function Background({ titles, suffix, subheading, icons }) {
  const element = useRef(null)

  useEffect(() => {
    window.addEventListener('mousemove', event =>
      moveParrallax(event, element.current)
    )
  }, [])

  return (
    <Root>
      <Image ref={element} />
    </Root>
  )
}

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
`

const Image = styled.div`
  height: 100%;
  width: 100%;
  background-image: url('https://res.cloudinary.com/wilbo/image/upload/v1556816861/wilbert.dev/stardust.png');
`

export default Background
